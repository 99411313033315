import React from "react";
import Layout from "./../components/Layout/Layout";
import { Link } from "react-router-dom";
import '../components/Layout/header.css';
import { useAuth } from "../context/auth";

const HomePage = () => {
  const [auth, setAuth] = useAuth();
  console.log(auth);
  return (
    <Layout title={"Best offers "}>
      <main>
      <pre>{JSON.stringify(auth, null, 4)}</pre>
  <section className="h-banner">
    <div className="container">
      <div className="row">
        {/* <div className="col-md-5">
          <div className="hm-silde owl-carousel owl-theme animate__fadeIn">
            <div className="hm-item">
              <img src="img/d-3.jpg" alt="error loading page"/>
            </div>
            <div className="hm-item">
              <img src="img/d-3.jpg" alt="error loading page"/>
            </div>
            <div className="hm-item">
              <img src="img/d-3.jpg" alt="error loading page"/>
            </div>
            <div className="hm-item">
              <img src="img/The_Dream_Diary7" alt="error loading page"/>
            </div>
            <div className="hm-item">
              <img src="img/d-3.jpg" alt="error loading page"/>
            </div>
            <div className="hm-item">
              <img src="img/d-3.jpg" alt="error loading page"/>
            </div>
            <div className="hm-item">
              <img src="img/d-3.jpg" alt="error loading page"/>
            </div>
            <div className="hm-item">
              <img src="img/d-3.jpg" alt="error loading page"/>
            </div>
          </div>
          <p className="being-scroll">SCROLL TO BEGIN ...</p>
        </div> */}
        <div className="col-md-7">
          <div className="hm-banner-cont">
            <h5>Welcome to the world of</h5>
            <h1>THE DREAM DIARY</h1>
            <h6>Inspired by the true essence </h6>
            <p>of capturing the moments.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="txbar">
    <div className="container">
      <div className="tx-head">
        <div className="tx-ct">
          <h2>
            The Dream Diary is a team of imaginary &amp; creative young people
            who are known for their out-of-the-box ideas with a lot of
            authenticity and passion. Creating Art is what they feel is in the
            DNA which keeps the team spirit always high while working on
            projects &amp; making them unique.
          </h2>
          {/*<h3>WINNER OF STILL LIFE CATEGORY - SONY BETTER PHOTOGRAPHY AWARDS 2019</h3>*/}
          {/*<h4>FUJIFILMX INDIA MENTOR</h4>*/}
          {/*<h5>PROFOTO LIGHTING PRO</h5>*/}
        </div>
      </div>
      <div className="wed-img">
        <div className="we-img io">
          <Link to="portfolio.html">
            <img src="img/d-3.jpg" alt="error loading page"/>
          </Link>
        </div>
        <div className="we-img io">
          <Link to="portfolio.html">
            <img src="img/d-3.jpg" alt="error loading page"/>
          </Link>
        </div>
        <div className="we-img io">
          <Link to="portfolio.html">
            <img src="img/d-3.jpg" alt="error loading page"/>
          </Link>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div className="container">
      <div className="pot-pol-hea">
        <a href="portfolio.html">
          <h1>the portfolio</h1>
          <p>BROWSE OUR WORK</p>
        </a>
      </div>
    </div>
  </section>
  <section className="art-abot-artist">
    <div className="container">
      <div className="about_artists">
        <div className="arti-abo">
          <div className="row">
            <div className="col-md-6">
              <div className="artis-img">
                <img src="admin/images/Untitled%20design%20(4).jpg" alt="error loading page"/>
              </div>
            </div>
            <div className="col-md-6">
              <div className="artis-detail">
                <div className="art-det">
                  <h3 className="se-t sie-about_3-text st-m-heading st-d-heading se-rc">
                    About the artists
                  </h3>
                  <h1 className="se-t sie-about_2-text st-m-title st-d-title">
                    MEET DREAMERS
                  </h1>
                  <p className="se-t sie-about_1-text st-m-paragraph st-d-paragraph" />
                  <p>
                    We are a Delhi-NCR based&nbsp;company who is known for its
                    prolific work in the field of photography &amp;
                    Cinematography. We have worked on several projects in
                    different genres and still counting and experimenting every
                    day. Our work is defined by our artistic approach towards
                    making a project different every time.
                    <br />
                    Shooting Weddings and Corporate films stunningly being our
                    USP.
                  </p>
                  <br />
                  <br />
                  <p />
                </div>
                <div className="cl-art-more">
                  <a href="about.html">READ MORE</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="follo-alone">
    <div className="container">
      <div className="artis">
        <h1>FOLLOW ALONG</h1>
        <h5>Visual Artistry</h5>
      </div>
      <div className="row">
        <div className="art-set">
          <div className="art-set-img">
            <div className="art-img">
              <Link
                target="_blank"
                to="https://www.instagram.com/thedreamdiary_/"
              >
                <img src="img/d-3.jpg" alt="error loading page" />
              </Link>
            </div>
            <div className="art-img">
              <Link
                target="_blank"
                to="https://www.instagram.com/thedreamdiary_/"
              >
                <img src="img/d-3.jpg" alt="error loading page"/>
              </Link>
            </div>
            <div className="art-img">
              <Link
                target="_blank"
                to="https://www.instagram.com/thedreamdiary_/"
              >
                <img src="img/d-3.jpg" alt="error loading page"/>
              </Link>
            </div>
            <div className="art-img">
              <Link
                target="_blank"
                to="https://www.instagram.com/thedreamdiary_/"
              >
                <img src="admin/images/The-Dream-Diary-Instagram-29%20copy.jpg" alt="error loading page"/>
              </Link>
            </div>
            <div className="art-img">
              <Link
                target="_blank"
                to="https://www.instagram.com/thedreamdiary_/"
              >
                <img src="img/d-3.jpg" alt="error loading page"/>
              </Link>
            </div>
          </div>
          <div className="art-set-img">
            <div className="art-img">
              <Link
                target="_blank"
                to="https://www.instagram.com/thedreamdiary_/"
              >
                <img src="admin/images/The-Dream-Diary-Instagram-12.jpg" alt="error loading page"/>
              </Link>
            </div>
            <div className="art-img">
              <Link
                target="_blank"
                to="https://www.instagram.com/thedreamdiary_/"
              >
                <img src="admin/images/The-Dream-Diary-Instagram-18.jpg" alt="error loading page"/>
              </Link>
            </div>
            <div className="art-img">
              <Link                target="_blank"
                to="https://www.instagram.com/thedreamdiary_/"
              >
                <img src="admin/images/The-Dream-Diary-Instagram-15.jpg" alt="error loading page"/>
              </Link>
            </div>
            <div className="art-img">
              <Link
                target="_blank"
                to="https://www.instagram.com/thedreamdiary_/"
              >
                <img src="admin/images/The-Dream-Diary-Instagram-17.jpg" alt="error loading page" />
              </Link>
            </div>
            <div className="art-img">
              <Link
                target="_blank"
                to="https://www.instagram.com/thedreamdiary_/"
              >
                <img src="admin/images/The-Dream-Diary-Instagram-22.jpg"  alt="error loading page"/>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="join-inst">
        <p>Join us on Instagram where we share</p>
        <p>FOLLOW ALONG</p>
        <div className="copy-right">@THE DREAM DIARY</div>
      </div>
    </div>
  </section>
</main>

    </Layout>
  );
};

export default HomePage;
