import React from "react";
import Layout from "./../components/Layout/Layout";
import "../styles/AuthStyles.css"

const About = () => {
  return (
    <Layout title={"About us - Ecommer app"}>
     <main>
  <section className="ab-banner">
    <div className="container">
      <div className="abt-head">
        <h6>Visual Artistry</h6>
        <h1>ABOUT</h1>
      </div>
      <div className="bane-ab">
        <img src="img/scan-mee-TDD.jpg" />
      </div>
      <div className="about-cont">
        <div className="row">
          <div className="col-md-6">
            <div className="ab-para-ct">
              <p>
                We are not just a bunch of just random photographers &amp;
                videographers instead we are people who are passionate enough to
                feel the moment and capture the true essence of it. We don’t
                work day and night just to finish the job given to us instead we
                work because as storytellers we believe that we have the power
                to tell a story in a heartfelt manner.{" "}
              </p>
              <p>
                The dream Dairy was started by Siddharth Chaudhry in Pune with 2
                people in his team. Now we have an in-house creative team of
                over 15 people ranging from photographers cinematographers,
                editors, and designers. Creativity flows in the blood of every
                team member.Our modern taste with an authentic twist works well
                with all the given work.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="ab-para-ct">
              <p>
                We are storytellers who believe that creativity should not have
                any end and it should just flow. Creating an experience of
                memories is what we aim at with our vision. We will surely make
                your memories come alive whenever you will go through the
                pictures and films we have made.{" "}
              </p>
              <p>
                Our main motive is to create an environment through our work,
                that can be cherished for a lifetime. It will transport you to a
                different world where you can realize the beauty of existence.
                We believe in originality and honest work which is always loved
                by our clients.Dream Diary is ready to give you a lifetime
                experience We will make sure that we turn your dream into a
                reality.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="studio">
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <div className="stud-img">
            <img src="img/About -Page-02.jpg" />
          </div>
        </div>
        <div className="col-md-8">
          <div className="st-ctn">
            <h5>Visual Artistry</h5>
            <h1>Our Philosophy</h1>
            <p>
              The study of ideas and beliefs about the meaning of life is what
              we aim at. And for us that is happiness. We make sure that people
              around us are always happy be it our clients or employees. In a
              world where numbers are more important than emotions, we make sure
              that we understand the emotions of our clients as well as
              employees. After all, experiencing and capturing emotion is also
              an art that takes a lot of hard work &amp; most importantly
              empathy.
            </p>
            <p>
              We believe in creating &amp; experiencing magic. The serenity in
              our hard work gives us the spirit of achieving the impossible. The
              way we work is like “WATER “as it flows with all the grace and
              also settles comfortably in any vessel.
            </p>
            <p>
              WellMoreover, Our young professionals are always updated with
              modern art and the latest trends which makes our work more
              Contemporary &amp; Chicer. Be it our team or our client, our
              priority is and will always be creating a shared value.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="our-team">
    <div className="container">
      <div className="tem-head">
        <h1>
          <strong>MEET THE TEAM</strong>
        </h1>
        <p>Responsible for making the magic happen.</p>
      </div>
      <div className="row">
        <div className="col-md-3 col-12">
          <div className="team-img">
            <img src="admin/images/Siddharth%20Chaudhary.jpg" />
            <h3>Siddharth Chaudhary</h3>
            <h4>Founder/DOP</h4>
            <p />
          </div>
        </div>
        <div className="col-md-3 col-12">
          <div className="team-img">
            <img src="admin/images/Nishad.jpg" />
            <h3>Nishad Alam</h3>
            <h4>Co - Founder/Photographer</h4>
            <p />
          </div>
        </div>
        <div className="col-md-3 col-12">
          <div className="team-img">
            <img src="admin/images/Rajiv%20Solanki.jpg" />
            <h3>Rajiv Kumar Solanki</h3>
            <h4>Senior Photographer</h4>
            <p />
          </div>
        </div>
        <div className="col-md-3 col-12">
          <div className="team-img">
            <img src="admin/images/Divya%20Sharma.jpg" />
            <h3>Divya Sharma</h3>
            <h4>Creative Head</h4>
            <p />
          </div>
        </div>
        <div className="col-md-3 col-12">
          <div className="team-img">
            <img src="admin/images/ODO%20copy.jpg" />
            <h3>Sushrut Gaur</h3>
            <h4>Sound Engineer </h4>
            <p />
          </div>
        </div>
        <div className="col-md-3 col-12">
          <div className="team-img">
            <img src="admin/images/Shadan%20Khan.jpg" />
            <h3>Shadan Khan</h3>
            <h4>Cinematographer</h4>
            <p />
          </div>
        </div>
        <div className="col-md-3 col-12">
          <div className="team-img">
            <img src="admin/images/Javed.jpg" />
            <h3>Javed Hassan</h3>
            <h4>Photographer</h4>
            <p />
          </div>
        </div>
      </div>
      {/* <div class="team-detail" style="padding-bottom: 0;">
  				
				
				
				
				
				
				
							
			</div>
			  */}
    </div>
  </section>
  <section className="favrit">
    <div className="container">
      <div className="favr owl-carousel owl-theme animate__fadeIn">
        <div className="fav-item">
          <div className="row">
            <div className="col-md-6">
              <div className="fav-img">
                <img src="img/wed5" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="fav-cont">
                <h5>Artistry &amp; Adventure</h5>
                <h1>Weddings </h1>
                <p>
                  DetailsWhen you travel back to the memories of your wedding,
                  we make sure that you revisit that emotion that was captured
                  by us. Those magical moments gave you goosebumps like seeing
                  the bride walking the aisle, or the groom cherishing the
                  bride. Our priority is to make sure that you reminisce on all
                  those previous occasions{" "}
                </p>
                <div className="dt">DETAILS</div>
              </div>
            </div>
          </div>
        </div>
        <div className="fav-item">
          <div className="row">
            <div className="col-md-6">
              <div className="fav-img">
                <img src="img/ " style={{}} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="fav-cont">
                <h5>Artistry &amp; Adventure</h5>
                <h1>Corporate Film</h1>
                <p>
                  We never see what emotions are usually put behind a corporate
                  film. A company is made of dedicated employees who put a lot
                  of emotions and hard work into making a company successful. We
                  capture that side of a corporate other than the stats and
                  data.It truly makes our corporate work stand alone.
                </p>
                <div className="dt">DETAILS</div>
              </div>
            </div>
          </div>
        </div>
        <div className="fav-item">
          <div className="row">
            <div className="col-md-6">
              <div className="fav-img">
                <img src="img/fa-1.jpg" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="fav-cont">
                <h5>Artistry &amp; Adventure</h5>
                <h1>Lifestyle</h1>
                <p>
                  There is a different approach in shooting lifestyle events as
                  one must truly be updated with what is in trend and should
                  have an eye for it. Not everyone can keep a tab on it. We make
                  sure that we deliver it at our best.
                </p>
                <div className="dt">DETAILS</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="follo-alone">
    <div className="container">
      <div className="artis">
        <h1>FOLLOW ALONG</h1>
        <h5>Visual Artistry</h5>
      </div>
      <div className="row">
        <div className="art-set">
          <div className="art-set-img">
            <div className="art-img">
              <a
                target="_blank"
                href="https://www.instagram.com/thedreamdiary_/"
              >
                <img src="img/The-Dream-Diary-Instagram-17.jpg" />
              </a>
            </div>
            <div className="art-img">
              <a
                target="_blank"
                href="https://www.instagram.com/thedreamdiary_/"
              >
                <img src="img/The-Dream-Diary-Instagram-18.jpg" />
              </a>
            </div>
            <div className="art-img">
              <a
                target="_blank"
                href="https://www.instagram.com/thedreamdiary_/"
              >
                <img src="img/The-Dream-Diary-Instagram-22.jpg" />
              </a>
            </div>
            <div className="art-img">
              <a
                target="_blank"
                href="https://www.instagram.com/thedreamdiary_/"
              >
                <img src="img/The-Dream-Diary-Instagram-29.jpg" />
              </a>
            </div>
            <div className="art-img">
              <a
                target="_blank"
                href="https://www.instagram.com/thedreamdiary_/"
              >
                <img src="img/The-Dream-Diary-Instagram-3.jpg" />
              </a>
            </div>
          </div>
          <div className="art-set-img">
            <div className="art-img">
              <a
                target="_blank"
                href="https://www.instagram.com/thedreamdiary_/"
              >
                <img src="img/The-Dream-Diary-Instagram-4" />
              </a>
            </div>
            <div className="art-img">
              <a
                target="_blank"
                href="https://www.instagram.com/thedreamdiary_/"
              >
                <img src="img/The-Dream-Diary-Instagram-6" />
              </a>
            </div>
            <div className="art-img">
              <a
                target="_blank"
                href="https://www.instagram.com/thedreamdiary_/"
              >
                <img src="img/The-Dream-Diary-Instagram-8" />
              </a>
            </div>
            <div className="art-img">
              <a
                target="_blank"
                href="https://www.instagram.com/thedreamdiary_/"
              >
                <img src="img/The-Dream-Diary-Instagram-12.jpg" />
              </a>
            </div>
            <div className="art-img">
              <a
                target="_blank"
                href="https://www.instagram.com/thedreamdiary_/"
              >
                <img src="img/The-Dream-Diary-Instagram-15.jpg" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="join-inst">
        <p>Join us on Instagram where we share</p>
        <p>FOLLOW ALONG</p>
        <div className="copy-right">@THE DREAM DIARY</div>
      </div>
    </div>
  </section>
</main>

    </Layout>
  );
};

export default About;
