import React from 'react';
import Layout from "./../components/Layout/Layout";
import "../styles/AuthStyles.css"

const portfolio = () => {
  return (
    <Layout title={"Best offers "}>

    <main>
  <section className="newstyles">
    <div className="container">
      <div className="vi-port">
        <div className="pot-cot pr-img">
          <h1>SELECT GALLERY</h1>
        </div>
        <div className="para">
          <p>
            Our work has always been known for its incredible piece of art. We
            understand our clients requirements thoroughly and deliver what they
            exactly want. Here are some of our unique works behind which we
            spent a lot of hard work and sleepless nights. The result is what
            satisfies us &amp; our clients too. These happy faces make us what
            we are today, and we proudly cherish these moments. These were some
            of the beautiful projects we did by putting all our hearts and
            brains into it.
          </p>
        </div>
      </div>
      <div className="portpoli imge hidden" >
        <div className="img-potoli pot-img">
          <a href="portfolio-innerd361.html">
            <div
              className="img-port part-two"
              style={{ backgroundImage: "url(admin/images/_ID_08040HD.jpg)" }}
            />
            <p>weddings</p>
          </a>
        </div>
        <div className="img-potoli pot-img">
          <a href="portfolio-innerc74e.html">
            <div
              className="img-port part-two"
              style={{ backgroundImage: "url(admin/images/_ID_08050HD.jpg)" }}
            />
            <p>Couples</p>
          </a>
        </div>
        <div className="img-potoli pot-img">
          <a href="portfolio-inner546e.html">
            <div
              className="img-port part-two"
              style={{ backgroundImage: 'url("img/jsdkoe.jpg")' }}
            />
            <p>EVENT</p>
          </a>
        </div>
      </div>
      <div className="portpoli imge">
        <div className="img-potoli pot-img">
          <a href="portfolio-innerd361.html">
            <div
              className="img-port part-two"
              style={{ backgroundImage: 'url("img/_ID_08040HD.jpg")' }}
            />
            <p>WEDDINGS</p>
          </a>
        </div>
        <div className="img-potoli pot-img">
          <a href="portfolio-innerc74e.html">
            <div
              className="img-port part-two"
              style={{ backgroundImage: 'url("img/_ID_08050HD.jpg")' }}
            />
            <p>COUPLES</p>
          </a>
        </div>
        <div className="img-potoli pot-img">
          <a href="portfolio-inner546e.html">
            <div
              className="img-port part-two"
              style={{ backgroundImage: 'url("img/jsdkoe.jpg")' }}
            />
            <p>EVENT</p>
          </a>
        </div>
      </div>
    </div>
  </section>
  
 
  
</main>

    </Layout>
  )
}

export default portfolio