import React from "react";
import { NavLink, Link } from "react-router-dom";
import './header.css';
import toast from "react-hot-toast";
import { useAuth } from "../../context/auth";

const Header = () => {
  const [auth,setAuth] = useAuth();
  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    toast.success("Logout Successfully");
  };
  return (
    <>
      <header className="dariy-header">
    <div className="container">
      <div className="logo">
        <div className="logo-img">
          <Link to="/">
            <img src="img/logo1.png" alt="Error loading page" />
          </Link>
        </div>
      </div>
      <div className="menu">
        <div className="nav">
          <nav id="myLinks">
            <ul className="d-menu navbar-nav ">
              <li className="nav-item">
                <NavLink to="/" className="nav-link ">
                  HOME
                </NavLink>
              </li>
              <li>
                <NavLink to="/Portfolio">PORTFOLIO</NavLink>
              </li>
              <li>
                <NavLink to="/Story">STORIES</NavLink>
              </li>
              <li>
                <NavLink to="/File">FILMS</NavLink>
              </li>
              <li>
                <NavLink to="/Testimonial">PRAISE</NavLink>
              </li>
              <li>
                <NavLink to="/About">ABOUT</NavLink>
              </li>
              <li>
                <NavLink to="/contact">INQUIRE</NavLink>
              </li>
              { !auth.user ? (
                <>
                  <li>  
                <NavLink to="/register">REGISTER</NavLink>
              </li>
              <li>
                <NavLink to="/login">LOGIN</NavLink>
              </li>
                </>
              ): (
                <>
                  <li className="nav-item dropdown">
                    <NavLink
                      className="nav-link dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {auth?.user?.name}
                    </NavLink>
                    <ul className="dropdown-menu">
                      <li>
                        <NavLink to={`/dashboard/${
                            auth?.user?.role === 1 ? "admin" : "user"
                          }`} className="dropdown-item">
                          Dashboard
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={handleLogout}
                          to="/login"
                          className="dropdown-item"
                        >
                          Logout
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </>
              )
                }
            </ul>
          </nav>
          {/* <div className="menu-icon" id="toggle">
            <span onclick="myFunction()" />
          </div> */}
        </div>
      </div>
    </div>
  </header>
    </>
  );
};

export default Header;
