import React from 'react'
import Layout from '../components/Layout/Layout'
// import "../styles/AuthStyles.css"
import "../App.css"

const Story = () => {
  return (
    <Layout title={"Best offers "}>
        <main>
  <sectin>
    {/* Slider Section Start */}    
    <div className="slider-area slider-six white-bg">
      <div className="container">
        <div className="row text-center">
          <div className="col-xs-12 col-sm-10 col-md-8 col-text-center">
            <div className="slider-text ptb-100 plr-60">
              <h1 className="font-50">CREATE.CAPTURE.CLICK.</h1>
              <p>
                We believe that every person is a storyteller and interestingly
                story maker as well. We give those stories a visual medium
                through which that story lasts forever. We use our shooting
                equipment like a magic wand thus creating experiences worth
                cherishing. Let us get to know some of the marvelous stories
                through all the story makers lenses.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Slider Section End */}
  </sectin>
  <section>
    {/* Portfolio Section Start */}
    <div className="portfolio-area portfolio-six white-bg section-padding clearfix">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="portfolio-menu hover-1 text-center">
              <ul className="clearfix mb-40">
                <li data-filter="*" className="active">
                  All
                </li>
                <li data-filter=".Weddings">Weddings</li>
                <li data-filter=".Fashion">Fashion</li>
                <li data-filter=".Lifestyle">Lifestyle</li>
                <li data-filter=".events">events</li>
              </ul>
            </div>
            <div className="portfolio-grid fitRows-grid  ">
              <div className="grid-item Weddings percent-33">
                <div className="single-portfolio">
                  <a href="stories-innerb91b.html?story=12">
                    <img src="img/stor.jpg" />
                  </a>
                </div>
                <div className="project-title text-center ptb-20">
                  <a href="stories-innerb91b.html?story=12">
                    <h4 className="mb-5">Rachna &amp; viraj</h4>
                  </a>
                  <p>...</p>
                </div>
                <div className="read-post">
                  <a className="href" href="stories-innerb91b.html?story=12">
                    Read Post
                  </a>
                </div>
              </div>
              <div className="grid-item events percent-33">
                <div className="single-portfolio">
                  <a href="stories-inner80ac.html?story=11">
                    <img src="admin/images/MOTOCROSS.jpg" />
                  </a>
                </div>
                <div className="project-title text-center ptb-20">
                  <a href="stories-inner80ac.html?story=11">
                    <h4 className="mb-5">MRF Mogrip</h4>
                  </a>
                  <p>...</p>
                </div>
                <div className="read-post">
                  <a className="href" href="stories-inner80ac.html?story=11">
                    Read Post
                  </a>
                </div>
              </div>
              <div className="grid-item Fashion percent-33">
                <div className="single-portfolio">
                  <a href="stories-inner5f73.html?story=9">
                    <img src="admin/images/Summer%20Solastic.jpg" />
                  </a>
                </div>
                <div className="project-title text-center ptb-20">
                  <a href="stories-inner5f73.html?story=9">
                    <h4 className="mb-5">Summer Solstice</h4>
                  </a>
                  <p>...</p>
                </div>
                <div className="read-post">
                  <a className="href" href="stories-inner5f73.html?story=9">
                    Read Post
                  </a>
                </div>
              </div>
              <div className="grid-item Weddings percent-33">
                <div className="single-portfolio">
                  <a href="stories-inner415b.html?story=7">
                    <img src="admin/images/best-candid-wedding-photographers-in-jabalpur-25.jpg" />
                  </a>
                </div>
                <div className="project-title text-center ptb-20">
                  <a href="stories-inner415b.html?story=7">
                    <h4 className="mb-5">Dharv &amp; Jahnvai</h4>
                  </a>
                  <p>...</p>
                </div>
                <div className="read-post">
                  <a className="href" href="stories-inner415b.html?story=7">
                    Read Post
                  </a>
                </div>
              </div>
            </div>
            <div className="view-all unset text-center" hidden="">
              <a className="btn mt-40" href="portfolio-3-style-4.html">
                View More <i className="zmdi zmdi-arrow-right" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Portfolio Section End */}
  </section>
  {/* <section className="taber" hidden="">
    <div className="container">
      <div className="tabber">
        <div className="tab">
          <button
            className="tablinks"
            onclick="openCity(event, 'event')"
            id="defaultOpen"
          >
            EVENTS
          </button>
          <button className="tablinks" onclick="openCity(event, 'WEDDING')">
            WEDDING
          </button>
          <button className="tablinks" onclick="openCity(event, 'STORIES')">
            STORIES
          </button>
          <button className="tablinks" onclick="openCity(event, 'FILMS')">
            FILMS
          </button>
          <button className="tablinks" onclick="openCity(event, 'PRAISE')">
            PRAISE
          </button>
          <button className="tablinks" onclick="openCity(event, 'ABOUT')">
            ABOUT{" "}
          </button>
          <button className="tablinks" onclick="openCity(event, 'INQUIRE')">
            INQUIRE
          </button>
        </div>
        <div id="event" className="tabcontent">
          <div className="row">
            <div className="col-md-3">
              <div className="tab-img">
                <img src="img/f-1.jpg" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="tab-img">
                <img src="img/f-2.jpg" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="tab-img">
                <img src="img/f-3.jpg" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="tab-img">
                <img src="img/f-4.jpg" />
              </div>
            </div>
          </div>
        </div>
        <div id="WEDDING" className="tabcontent">
          <div className="row">
            <div className="col-md-3">
              <div className="tab-img">
                <img src="img/f-1.jpg" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="tab-img">
                <img src="img/f-2.jpg" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="tab-img">
                <img src="img/f-3.jpg" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="tab-img">
                <img src="img/f-4.jpg" />
              </div>
            </div>
          </div>
        </div>
        <div id="STORIES" className="tabcontent">
          <div className="row">
            <div className="col-md-3">
              <div className="tab-img">
                <img src="img/f-1.jpg" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="tab-img">
                <img src="img/f-2.jpg" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="tab-img">
                <img src="img/f-3.jpg" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="tab-img">
                <img src="img/f-4.jpg" />
              </div>
            </div>
          </div>
        </div>
        <div id="FILMS" className="tabcontent">
          <div className="row">
            <div className="col-md-3">
              <div className="tab-img">
                <img src="img/f-1.jpg" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="tab-img">
                <img src="img/f-2.jpg" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="tab-img">
                <img src="img/f-3.jpg" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="tab-img">
                <img src="img/f-4.jpg" />
              </div>
            </div>
          </div>
        </div>
        <div id="PRAISE" className="tabcontent">
          <div className="row">
            <div className="col-md-3">
              <div className="tab-img">
                <img src="img/f-1.jpg" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="tab-img">
                <img src="img/f-2.jpg" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="tab-img">
                <img src="img/f-3.jpg" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="tab-img">
                <img src="img/f-4.jpg" />
              </div>
            </div>
          </div>
        </div>
        <div id="ABOUT" className="tabcontent">
          <div className="row">
            <div className="col-md-3">
              <div className="tab-img">
                <img src="img/f-1.jpg" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="tab-img">
                <img src="img/f-2.jpg" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="tab-img">
                <img src="img/f-3.jpg" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="tab-img">
                <img src="img/f-4.jpg" />
              </div>
            </div>
          </div>
        </div>
        <div id="INQUIRE" className="tabcontent">
          <div className="row">
            <div className="col-md-3">
              <div className="tab-img">
                <img src="img/f-1.jpg" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="tab-img">
                <img src="img/f-2.jpg" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="tab-img">
                <img src="img/f-3.jpg" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="tab-img">
                <img src="img/f-4.jpg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> */}
  {/* <section className="date-name" hidden="">
    <div className="container">
      <div className="client">
        <div className="cl-date">
          <div className="date">7/28/20</div>
          <div className="name">Payal &amp; Thomas</div>
        </div>
        <div className="cli-para">
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.
          </p>
        </div>
        <div className="location">
          <p>
            <span>Location</span> - Alsisar Mahal , Rajasthan
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 pr-0">
          <div className="stor-img">
            <img src="img/s-2.jpg" />
          </div>
        </div>
        <div className="col-md-6 pl-0">
          <div className="stor-img">
            <img src="img/s-3.jpg" />
          </div>
        </div>
        <div className="col-md-12">
          <div className="stori-img">
            <img src="img/s-1.jpg" />
          </div>
        </div>
      </div>
    </div>
  </section> */}
</main>

    </Layout>
  )
}

export default Story