import React, { useState } from "react";
import Layout from '../components/Layout/Layout';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import "../styles/AuthStyles.css";
const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [date, setDate] = useState("");
  const [refer, setRefer] = useState("");
  const [address, setAddress] = useState("");
  const navigate = useNavigate();

   // form function
   const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/api/v1/auth/contact", {
        name,
        email,
        phone,
        date,
        refer,
        address,
      });
      if (res && res.data.success) {
        toast.success(res.data && res.data.message);
        setTimeout(()=>{
          navigate("/");
        },2000);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };
  return (
    <Layout title={"Contact us"}>
     <main>
    <section>
      <div className="container">
        <div className="cont-inq">
          <div className="row">
            <div className="col-md-7">
              <div className="contact-fom">
                <div className="fom-content">
                  <h5>Visual Artistry</h5>
                  <h4>CONTACT US</h4>
                  <p />
                  <p>
                    For queries &amp; collections , feel free to email us
                    directly at thedreamdiaryproductions@gmail.com&nbsp;or call
                    us at +91-9667116341
                  </p>
                  <p />
                </div>
                <div className="from-box">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        required="true"
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                        placeholder="NAME"
                        defaultValue=""
                        className="dr-fom"
                      />
                    </div>
                    <div className="form-group-ct">
                      <input
                        type="email"
                        required="true"
                        onChange={(e) => setEmail(e.target.value)}
                        name="email"
                        placeholder="EMAIL"
                        defaultValue=""
                        className="dr-fom"
                      />
                    </div>
                    <div className="form-group-ct">
                      <input
                        type="number"
                        required=""
                        name="phone"
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="PHONE"
                        defaultValue=""
                        className="dr-fom"
                      />
                    </div>
                    <div className="form-group-ct">
                      <input
                        type="date"
                        required=""
                        onChange={(e) => setDate(e.target.value)}
                        name="date"
                        placeholder="EVENT"
                        defaultValue=""
                        className="dr-fom"
                      />
                    </div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        required=""
                        name="refer"
                        onChange={(e) => setRefer(e.target.value)}
                        placeholder="RFR BY"
                        defaultValue=""
                        className="dr-fom"
                      />
                    </div>
                    <div className="form-group-ct">
                      <textarea
                        className=""
                        required=""
                        onChange={(e) => setAddress(e.target.value)}
                        name="address"
                        placeholder="MSG"
                        defaultValue={""}
                      />
                    </div>
                    <div className="btn-socil">
                      <div className="soical">
                        <ul></ul>
                      </div>
                      <div className="btn-contac" >
                        <button type="submit" style={{cursor:"pointer",background:"black",padding:"10px 12px"}}>
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="ct-img-part">
                <div className="img-ct">
                  <div className="btn-img">
                    <img src="admin/images/Insta%203.jpg" alt='Error loading page' />
                  </div>
                  <div className="btn-img">
                    <img src="admin/images/Insta%202.jpg" alt='Error loading page'/>
                  </div>
                  <div className="btn-img">
                    <img src="admin/images/Insta%201.jpg" alt='Error loading page'/>
                  </div>
                </div>
                <div className="img-ct">
                  <div className="btn-img">
                    <img src="admin/images/insta%208.jpg" alt='Error loading page'/>
                  </div>
                  <div className="btn-img">
                    <img src="admin/images/insta%207.jpg"  alt='Error loading page'/>
                  </div>
                  <div className="btn-img">
                    <img src="admin/images/insta%204.jpg" alt='Error loading page'/>
                  </div>
                </div>
                <div className="img-ct">
                  <div className="btn-img">
                    <img src="admin/images/insta%2033.jpg" alt='Error loading page'/>
                  </div>
                  <div className="btn-img">
                    <img src="admin/images/Insta%2011.jpg" alt='Error loading page' />
                  </div>
                  <div className="btn-img">
                    <img src="admin/images/insta%2022.jpg" alt='Error loading page'/>
                  </div>
                </div>
                <div className="img-ct">
                  <div className="btn-img">
                    <img src="admin/images/insta%2023.jpg" alt='Error loading page' />
                  </div>
                  <div className="btn-img">
                    <img src="admin/images/insta%2010.jpg" alt='Error loading page'/>
                  </div>
                  <div className="btn-img">
                    <img src="admin/images/insta%2012.jpg" alt='Error loading page'/>
                  </div>
                </div>
              </div>
              <div className="join-inst">
                <p>Join us on Instagram where we share</p>
                <p>our visual artistry on the daily.</p>
                <div className="copy-right">
                  <p>@THE DREAM DIARY</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
    </Layout>
  );
};

export default Contact;
