import React from 'react'
import Layout from '../components/Layout/Layout'
import "../styles/AuthStyles.css"

const Testimonial = () => {
  return (
    <Layout title={"Best offers "}>
<main style={{height:"100vh"}}> 
  <section>
    <div className="container">
      <div className="abt-head">
        <h1>PRAISE</h1>
      </div>
      <div className="d-para">
        <div className="par-part owl-carousel owl-theme animate__fadeIn">
          <div className="first-slider">
            <div className="sl-img">
              <div className="d-img-part">
                <div className="img-d">
                  <div className="dl-im">
                    <img
                      src="img/pre-3.jpg"
                      style={{ float: "right !important" }}
                    />
                  </div>
                </div>
                <div className="img-d">
                  <div className="dl-im">
                    <img src="img/pre-4.jpg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="sli-cont">
              <h1>SHEFALI &amp; AKASH</h1>
              <p>
                I couldn't have been happier with my decision to choose The
                Dream Diary for my wedding photography. They covered our
                pre-wedding shoot as well as all the events (the Wedding,
                Sangeet, and Reception). Photographs are such an important part
                of a wedding and I remember scouting through scores of
                photographers before zeroing down on The Dream Diary&nbsp;and
                I'm so glad I did!
              </p>
            </div>
          </div>
          <div className="first-slider">
            <div className="sl-img">
              <div className="d-img-part">
                <div className="img-d">
                  <div className="dl-im">
                    <img
                      src="img/pre1.jpg"
                      style={{ float: "right !important" }}
                    />
                  </div>
                </div>
                <div className="img-d">
                  <div className="dl-im">
                    <img src="img/pre2.jpg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="sli-cont">
              <h1>Rachna &amp; viraj</h1>
              <p>
                The Dream Diary &amp;&nbsp;team is a thorough professional.
                Right from the moment I reached out to him till the point we got
                the final pictures, it was a breeze working with him. The team
                is&nbsp;sensible and receptive to the client's inputs and works
                hard to make sure he delivers. ~ Even though we were camera-shy
                people, Siddharth got us comfortable by just letting us be.
                After a point, we forgot there was a photographer around us!
                Every picture turned out just perfect with his unique
                photography sense.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* <section className="bb-slid">
    <div className="container">
      <div className="fd-silde owl-carousel owl-theme animate__fadeIn">
        <div className="fd-item">
          <img src="img/wdk.jpg" />
        </div>
      </div>
    </div>
  </section>
  <section className="pr-press">
    <div className="container">
      <div className="drm">
        <div className="row">
          <div className="col-md-4">
            <div className="press">
              <div className="seen-p">
                <h5>Press</h5>
                <h4>AS SEEN IN</h4>
                <p />
                <p>
                  We are always filled with joy when we have the privilege to
                  hear from our clients after the work is done. The feedback is
                  important to us as it gives us satisfaction with our work. A
                  satisfied client is all we need. We also thank our clients to
                  give us the courage and supporting our imagination and our
                  craft of making films. Happier our clients, happier we. Thus,
                  making everyone happy &amp; satisfied. Seeing the joy in our
                  Clients gives us the purpose &amp; meaning toward working
                  harder. We get immense pleasure after seeing the happy faces
                  through our work. We are glad that we have converted every
                  opportunity into an amazing journey for our clients.
                </p>
                <p />
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="pr-im">
              <div className="p-img-part">
                <div className="f-img-firt">
                  <img src="admin/images/3.jpg" />
                </div>
                <div className="f-img-firt">
                  <img src="admin/images/1.jpg" />
                </div>
              </div>
              <div className="p-img-part">
                <div className="f-img-firt">
                  <img src="admin/images/2.jpg" />
                </div>
                <div className="f-img-firt">
                  <img src="admin/images/5.jpg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> */}
</main>

    </Layout>
  )
}

export default Testimonial