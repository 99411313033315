import React from 'react'
import Layout from '../components/Layout/Layout'
import "../styles/AuthStyles.css"

const File = () => {
  return (
    <Layout title={"Best offers "}>
        <main>
  <section className="video-banne">
    <div className="abt-head">
      <h1>Films</h1>
    </div>
    <div className="vide">
      <iframe
        style={{ width: "100%", height: 645 }}
        src="https://www.youtube.com/embed/jGApYMK1QMY"
        title="YouTube video player"
        frameBorder={0}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen=""
        className="processed"
        id="fitvid824227"
      >
        YT
      </iframe>
    </div>
  </section>
  <section className="follo-alone" hidden="">
    <div className="container">
      <div className="artis">
        <h5>Visual Artistry</h5>
        <h1>FOLLOW ALONG</h1>
      </div>
      <div className="row">
        <div className="art-set">
          <div className="art-set-img">
            <div className="art-img">
              <img src="img/d-7.jpg" />
            </div>
            <div className="art-img">
              <img src="img/d-7.jpg" />
            </div>
            <div className="art-img">
              <img src="img/d-8.jpg" />
            </div>
            <div className="art-img">
              <img src="img/d-9.jpg" />
            </div>
            <div className="art-img">
              <img src="img/d-10.jpg" />
            </div>
          </div>
          <div className="art-set-img">
            <div className="art-img">
              <img src="img/d-11.jpg" />
            </div>
            <div className="art-img">
              <img src="img/d-12.jpg" />
            </div>
            <div className="art-img">
              <img src="img/d-13.jpg" />
            </div>
            <div className="art-img">
              <img src="img/d-14.jpg" />
            </div>
            <div className="art-img">
              <img src="img/d-16.jpg" />
            </div>
          </div>
        </div>
      </div>
      <div className="join-inst">
        <p>
          Join us on Instagram where we share our visual artistry on the daily.
        </p>
      </div>
    </div>
  </section>
  <section className="follo-alone">
    <div className="container">
      <div className="artis">
        <h5>One of the tallest building in INDIA</h5>
        <h1>Supertech Supernova</h1>
      </div>
      <div className="video-banner">
        <iframe
          style={{ width: "100%", height: 400 }}
          src="https://www.youtube.com/embed/gBJhY5XCjE0"
          title="YouTube video player"
          frameBorder={0}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen=""
          className="processed"
          id="fitvid824227"
        >
          YT
        </iframe>
        `
      </div>
      <div className="Jaipur">
        <h3 />
        <h4 />
      </div>
    </div>
  </section>
  <section className="follo-alone">
    <div className="container">
      <div className="artis">
        <h5>Euphoria of dreams - Gulling school</h5>
        <h1>PIN VALLEY NATIONAL PARK</h1>
      </div>
      <div className="video-banner">
        <iframe
          style={{ width: "100%", height: 400 }}
          src="https://www.youtube.com/embed/rKdBjULSuj8"
          title="YouTube video player"
          frameBorder={0}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen=""
          className="processed"
          id="fitvid824227"
        >
          YT
        </iframe>
        `
      </div>
      <div className="Jaipur">
        <h3 />
        <h4 />
      </div>
    </div>
  </section>
  <section className="follo-alone">
    <div className="container">
      <div className="artis">
        <h5>Best Pre Wedding Shoot Locations in Delhi/NCR</h5>
        <h1>Sunil &amp; Twinkle</h1>
      </div>
      <div className="video-banner">
        <iframe
          style={{ width: "100%", height: 400 }}
          src="https://www.youtube.com/embed/YJ5OEjvLi9E"
          title="YouTube video player"
          frameBorder={0}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen=""
          className="processed"
          id="fitvid824227"
        >
          YT
        </iframe>
        `
      </div>
      <div className="Jaipur">
        <h3 />
        <h4 />
      </div>
    </div>
  </section>
  <section className="follo-alone">
    <div className="container">
      <div className="artis">
        <h5>Events In Delhi/NCR</h5>
        <h1>Corporate SHOWREEL</h1>
      </div>
      <div className="video-banner">
        <iframe
          style={{ width: "100%", height: 400 }}
          src="https://www.youtube.com/embed/ZcQO_-RGHgE"
          title="YouTube video player"
          frameBorder={0}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen=""
          className="processed"
          id="fitvid824227"
        >
          YT
        </iframe>
        `
      </div>
      <div className="Jaipur">
        <h3 />
        <h4 />
      </div>
    </div>
  </section>
  <section className="follo-alone">
    <div className="container">
      <div className="artis">
        <h5>A Hidden gem in the lap of Himalayas</h5>
        <h1>Unexplored Uttarakhand</h1>
      </div>
      <div className="video-banner">
        <iframe
          style={{ width: "100%", height: 400 }}
          src="https://www.youtube.com/embed/_XkA8tzQj4g"
          title="YouTube video player"
          frameBorder={0}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen=""
          className="processed"
          id="fitvid824227"
        >
          YT
        </iframe>
        `
      </div>
      <div className="Jaipur">
        <h3 />
        <h4 />
      </div>
    </div>
  </section>
</main>

    </Layout>
  )
}

export default File